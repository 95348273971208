import PropTypes from 'prop-types';
import { formatPrice } from 'sportsbook-helpers';
import withOutcome from 'sportsbook-with-outcome';
import OutcomeView from './outcome_view';

const OutcomeContainer = ({
  outcome,
  enhancedOutcome,
  selected,
  toggleBetslip,
}) => {
  const {
    id, // Outcome Id
    sDesc, // Short Description
    h, // Hidden
    pr, // Fractional price as string
  } = outcome;

  return (
    <OutcomeView
      outcomeId={id}
      outcomeDesc={sDesc}
      outcomeClassName={`bvs-button-multi-sport ${selected ? 'active' : ''}`}
      onClick={h ? () => {} : toggleBetslip}
      price={!h ? formatPrice(pr) : '-'}
      fractionalEnhancedOdds={
        (enhancedOutcome && formatPrice(enhancedOutcome.fractionalEnhancedOdds)) || null
      }
    />
  );
};

OutcomeContainer.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcome: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default withOutcome(OutcomeContainer, { idAttribute: 'outcomeId' });

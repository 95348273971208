import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import EventsGroupHeaderView from './events_group_header_view';

const EventsGroupHeaderContainer = ({ eventIds }) => {
  const config = useContext(ConfigContext);

  const {
    couponHeaders,
  } = config;

  return (
    <EventsGroupHeaderView
      eventIds={eventIds}
      couponHeaders={couponHeaders}
    />
  );
};

EventsGroupHeaderContainer.propTypes = {
  eventIds: PropTypes.instanceOf(Array).isRequired,
};

export default EventsGroupHeaderContainer;

import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplateContext, ConfigContext } from 'SportsbookOverview/contexts';
import { SeeMoreMarkets } from 'SportsbookOverview/components/see_more_markets';
import { Suspended } from '../suspended';
import MarketView from './market_view';

const MarketContainer = ({ eventId, market, outcomeIds }) => {
  const { outcomeComponent } = useContext(TemplateContext);
  const { couponHeaders } = useContext(ConfigContext);

  return (
    <MarketView
      eventId={eventId}
      marketId={market && market.id}
      marketAbsent={!market || market.o.length === 0}
      marketSuspended={market && market.st === 2}
      suspendedComponent={Suspended}
      seeMoreMarketsComponent={SeeMoreMarkets}
      outcomeComponent={outcomeComponent}
      outcomeIds={outcomeIds}
      couponHeaders={couponHeaders}
    />
  );
};

MarketContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  outcomeIds: PropTypes.instanceOf(Array).isRequired,
};

export default MarketContainer;

import PropTypes from 'prop-types';
import { formatPrice } from 'sportsbook-helpers';
import withOutcome from 'sportsbook-with-outcome';
import OutcomeView from '../default/outcome_view';

const OutcomeContainer = ({
  outcome,
  selected,
  toggleBetslip,
}) => {
  const { id, h, pr } = outcome;

  return (
    <OutcomeView
      outcomeId={id}
      outcomeClassName={`bvs-button-multi-sport ${selected ? 'active' : ''}`}
      onClick={h ? () => {} : toggleBetslip}
      price={!h ? formatPrice(pr) : '-'}
    />
  );
};

OutcomeContainer.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default withOutcome(OutcomeContainer, { idAttribute: 'outcomeId' });

import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import classNames from 'classnames';

const EventsGroupView = ({
  groupTitle,
  hideGroupTitle,
  eventIds,
  initialVisibleEvents,
  allOtherEvents,
  seeAllEvents,
  expandAllEvents,
  eventsGroupHeaderComponent,
  eventComponent,
}) => {
  const EventsGroupHeader = eventsGroupHeaderComponent;
  const Event = eventComponent;

  return (
    <>
      <div className={classNames('inplay-competition-header-row', {
        'hidden-header': hideGroupTitle,
      })}
      >
        <div className="inplay-coupon-favcompetition-column">
          {!hideGroupTitle && (
            <h3 className="inplay-coupon-competition-label">{ groupTitle }</h3>
          )}
        </div>

        <EventsGroupHeader
          eventIds={eventIds}
        />
      </div>
      {
        initialVisibleEvents.map((eventId) => (
          <Event
            key={eventId}
            eventId={eventId}
          />
        ))
      }

      {
        !seeAllEvents && allOtherEvents.length > 0 && (
          <div className="inplay-coupon-row more-button-wrapper" onClick={expandAllEvents}>
            <Button
              primary
              label={t('javascript.see_all_events')}
            />
          </div>
        )
      }

      {
        seeAllEvents && (
          allOtherEvents.map((eventId) => (
            <Event
              key={eventId}
              eventId={eventId}
            />
          ))
        )
      }
    </>
  );
};

EventsGroupView.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  hideGroupTitle: PropTypes.bool,
  eventIds: PropTypes.instanceOf(Array).isRequired,
  initialVisibleEvents: PropTypes.instanceOf(Array).isRequired,
  allOtherEvents: PropTypes.instanceOf(Array).isRequired,
  seeAllEvents: PropTypes.bool.isRequired,
  expandAllEvents: PropTypes.func.isRequired,
  eventsGroupHeaderComponent: PropTypes.node.isRequired,
  eventComponent: PropTypes.node.isRequired,
};

EventsGroupView.defaultProps = {
  hideGroupTitle: false,
};

export default EventsGroupView;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetMarket } from 'sportsbook-markets-selector';
import selectors from 'SportsbookOverview/redux/selectors';
import MarketContainer from './market_container';

const MarketRedux = ({ eventId, marketId }) => {
  // Stablish redux selector to extract the market for the given event id
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, { marketId }), isEqual);
  // --------------------------------------------

  // Stablish redux selector to get the sorted outcome ids for the market
  const getDefaultMarketOutcomeIds = useMemo(
    selectors.makeGetDefaultMarketOutcomeIds,
    [],
  );
  const outcomeIds = useSelector(
    (state) => getDefaultMarketOutcomeIds(state, { marketId }), isEqual,
  );
  // --------------------------------------------

  return (
    <MarketContainer
      eventId={eventId}
      outcomeIds={outcomeIds}
      market={market}
    />
  );
};

MarketRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.number,
};

MarketRedux.defaultProps = {
  marketId: null,
};

export default MarketRedux;

import { difference, chunk } from 'underscore';
import { addOutcomes } from 'sportsbook-outcomes-duck';
import { addMarkets } from 'sportsbook-markets-duck';
import actionCreators from 'SportsbookOverview/redux/action_creators';
import { fetchOverviewMarkets } from 'SportsbookOverview/api';
import selectors from 'SportsbookOverview/redux/selectors';
import { toDecimal } from 'bv-helpers/price';
import { setPrerenderReady } from 'bv-services/seo';

// TODO: Extract this
const outcomePresenter = (outcome) => ({
  id: parseInt(outcome.id, 10),
  desc: outcome.d,
  prid: outcome.cpid,
  pr: outcome.pr,
  prd: outcome.prd || toDecimal(outcome.pr),
  formattedWasPrice: outcome.formattedWasPrice,
  sDesc: outcome.sd,
  h: outcome.h,
  po: outcome.po,
  legDescriptions: outcome.legDescriptions,
  timestamp: outcome.timestamp,
  key: outcome.key,
});

const marketPresenter = (market) => ({
  id: parseInt(market.id, 10),
  mtId: parseInt(market.mtid, 10),
  desc: market.desc,
  eId: parseInt(market.eid, 10),
  pltD: market.ptd,
  pltDes: market.ptdesc,
  st: market.st,
  mbl: market.mblindex,
  current: market.cur,
  next: market.next,
  pid: market.pid,
  o: market.o,
  outcomesDescription: market.outcomesDescription,
});
// ---------------------------------

const BATCH_SIZE = 100;

export default ({
  marketTypeDimension,
  periodDimension,
  boosted,
  outcomesPerEvent,
  skipFetch,
  couponOutcomeKeys,
  ignoreOutcomeKeys,
}) => (dispatch, getState) => {
  if (skipFetch) return;

  const visibleEventIds = selectors.getVisibleEventIds(getState());
  const alreadyFetchedEventIds = Object.keys(selectors.getEventsActiveMarket(getState()))
    .map((id) => parseInt(id, 10));
  const eventIds = difference(visibleEventIds, alreadyFetchedEventIds);

  if (eventIds.length > 0) {
    const batches = chunk(eventIds, BATCH_SIZE);

    dispatch(actionCreators.fetchMarketsInit());
    dispatch(actionCreators.setEventsActiveMarketInit(eventIds));

    Promise.all(
      batches.map((batchEventIds) => fetchOverviewMarkets({
        eventIds: batchEventIds,
        marketTypeDimension,
        periodDimension,
        boosted,
        outcomesPerEvent,
        couponOutcomeKeys,
        ignoreOutcomeKeys,
      }).then((marketsResponse) => {
        dispatch(addOutcomes((marketsResponse?.outcomes || []).map(outcomePresenter)));
        dispatch(addMarkets((marketsResponse?.markets || []).map(marketPresenter)));
        if (marketsResponse?.eventMarkets) {
          dispatch(actionCreators.setEventsActiveMarket(marketsResponse.eventMarkets));
        }
      })),
    ).then(() => {
      dispatch(actionCreators.fetchMarketsSuccess());
      setPrerenderReady();
    });
  }
};

import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConfigContext } from 'SportsbookOverview/contexts';
import setCardHasMarkets from 'SportsbookOverview/redux/action_creators/set_card_has_markets';
import makeGetEventsMarketsIds from 'SportsbookOverview/redux/selectors/make_get_events_markets_ids';
import EventsGroupContainer from './events_group_container';

const getEventsMarketsIds = makeGetEventsMarketsIds();
const hideEmptyMarketCouponTypes = ['bet_boost', 'enhanced_accas'];

const EventsGroupRedux = ({ cardId, cardHasMarkets, eventsGroup }) => {
  const dispatch = useDispatch();
  const { couponType, linkType } = useContext(ConfigContext);
  const hideEmptyEvents = hideEmptyMarketCouponTypes.includes(couponType?.value)
    || hideEmptyMarketCouponTypes.includes(linkType?.value);
  const eventsMarketIds = useSelector(
    (state) => getEventsMarketsIds(state, { eventIds: eventsGroup?.children }),
  );
  if (hideEmptyEvents && !eventsMarketIds?.length) {
    return null;
  }

  if (!cardHasMarkets) {
    dispatch(setCardHasMarkets({ cardId }));
  }

  return (
    <EventsGroupContainer
      eventsGroup={eventsGroup}
    />
  );
};

EventsGroupRedux.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardHasMarkets: PropTypes.bool.isRequired,
  eventsGroup: PropTypes.instanceOf(Object).isRequired,
};

export default EventsGroupRedux;
